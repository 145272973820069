import styled from 'styled-components';
import { variables } from 'utils/variables';

const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: ${variables.spacing.xs}px;

  span {
    margin-right: ${variables.spacing.xs}px;
  }
`;

export { NoteWrapper, TagWrapper };
