import { FieldTimeOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { variables } from 'utils/variables';
import { colors, palette } from 'utils/colors';

const Icon = styled(FieldTimeOutlined)`
  margin-right: ${variables.spacing.xxs}px;
  color: ${palette[colors.PRIMARY][400]};
`;

const Note = styled.small`
  display: block;
  white-space: pre-wrap;
  margin-bottom: ${variables.spacing.s}px;
  color: ${palette[colors.GREY][400]};
  opacity: 0.9;

  ${(props) =>
    props.$footnote &&
    `
  margin-top: ${variables.spacing.s}px;

  &::before {
    content: '*';
    display: inline;
  }
  `}
`;

export { Icon, Note };
