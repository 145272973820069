import { useState, useCallback, useMemo, useContext } from 'react';
import { Button, Modal, Space, Tag } from 'antd';
import { Line } from '@ant-design/plots';
import { PieChartOutlined } from '@ant-design/icons';
import { colors } from 'utils/colors';
import messages from 'utils/messages';
import { LoginContext } from 'pages/Router';
import { getPublisherBookViewsStats } from '../../api/services/StatsPublisherService';
import TooltipContent from '../TooltipContent/TooltipContent';
import TimeFrameTag from 'components/TimeFrameTag/TimeFrameTag';
import { dateRangeValues } from 'utils/dateRanges';
import SmallNote from 'components/SmallNote/SmallNote';
import { TagWrapper, NoteWrapper } from './ChartButtonStyles';

const ChartButton = ({ bookId, modalTitle, timeFrame }) => {
  const [visible, setVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const { token, logout } = useContext(LoginContext);
  const [dataTimeline, setDataTimeline] = useState([]);
  const [dataAdditional, setDataAdditional] = useState({});

  const titleWithTimeFrame = (
    <Space wrap>
      {modalTitle}
      <TimeFrameTag timeFrame={timeFrame} />
    </Space>
  );

  const lineChart = useCallback((timelineData) => {
    const timeline = timelineData
      .map((item) => {
        return {
          time: item.created_at.substring(0, 10),
          wyświetlenia: parseInt(item.page_views),
        };
      })
      .reverse();
    const config = {
      data: timeline,
      height: 150,
      xField: 'time',
      yField: 'wyświetlenia',
      label: {},
      point: {
        size: 5,
        shape: 'circle',
        style: {
          fill: 'white',
          stroke: colors.PRIMARY,
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
        customContent: (title, items) => {
          return <TooltipContent title={title} items={items} viewsLabel={'Wyświetlenia'}></TooltipContent>;
        },
      },
      state: {
        active: {
          style: {
            shadowColor: colors.PRIMARY,
            shadowBlur: 4,
            stroke: 'transparent',
            fill: colors.PRIMARY,
          },
        },
      },
      lineStyle: {
        stroke: colors.PRIMARY,
        lineWidth: 4,
        lineJoin: 'round',
        lineCap: 'round',
      },
      theme: {
        geometries: {
          point: {
            diamond: {
              active: {
                style: {
                  shadowColor: colors.PRIMARY,
                  shadowBlur: 2,
                  stroke: colors.PRIMARY,
                },
              },
            },
          },
        },
      },
      interactions: [{ type: 'marker-active' }],
    };
    return <Line {...config} />;
  }, []);

  const fetchData = useCallback(async () => {
    const response = await getPublisherBookViewsStats(token, logout, bookId, dateRangeValues[timeFrame]);
    if (response === null) {
      setIsError(true);
    }
    setDataTimeline(response.book_views_timeline);
    setDataAdditional(response.book_data);
  }, [logout, token, bookId, timeFrame]);

  const chart = useMemo(() => {
    return lineChart(dataTimeline);
  }, [lineChart, dataTimeline]);

  const onClick = useCallback(() => {
    setIsError(false);
    setVisible(true);
    fetchData();
  }, [fetchData]);

  // TODO: remove and adjust code when empty array of tags will be really empty, getPublisherBookViewsStats
  const isTagArrayEmpty = (tagsArray) => tagsArray.length === 0 || tagsArray.every((tag) => tag.length === 0);
  const getYearMonthDay = (dataString) => dataString.split(' ')[0];

  return (
    <>
      <Button type="primary" icon={<PieChartOutlined />} size="small" onClick={() => onClick()}>
        Rozkład ruchu
      </Button>
      <Modal
        width={900}
        title={titleWithTimeFrame}
        open={visible}
        okText="Zamknij okno"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        cancelButtonProps={{ style: { visibility: 'hidden' } }}
      >
        <NoteWrapper>
          <SmallNote note={`W Lubimyczytać od: ${dataAdditional.created_at ? getYearMonthDay(dataAdditional.created_at) : '-'}`} />
          <SmallNote note={`Pierwsze pobranie statystyk: ${dataAdditional.first_ga_fetch ? getYearMonthDay(dataAdditional.first_ga_fetch) : '-'}`} />
        </NoteWrapper>
        <br />
        {isError ? <p>{messages.errorFetchData}</p> : dataTimeline ? chart : <p>Ładowanie danych...</p>}
        <br />
        <TagWrapper>
          <span>Tagi książki:</span>
          {dataAdditional.tags && !isTagArrayEmpty(dataAdditional.tags) ? (
            dataAdditional.tags.map((item, index) => <Tag key={`${item}${index}`}>{item}</Tag>)
          ) : (
            <SmallNote note="Książka nie ma tagów. Otaguj ją, aby zwiększyć widoczność i liczbę odwiedzin." footnote />
          )}
        </TagWrapper>
      </Modal>
    </>
  );
};

export default ChartButton;
