import errorCodes from 'utils/errorCodes';
import responseCodes from '../../utils/responseCodes';

export const getPublisherStats = async (token, logoutAction, daysBack) => {
  return await getData(token, 'https://lubimyczytac.pl/publisher_data/api/stats', logoutAction, (data) => data.data?.stats, daysBack);
};

export const getExternalPublisherStats = async (token, logoutAction, daysBack) => {
  return await getData(token, 'https://lubimyczytac.pl/publisher_data/api/external-stats', logoutAction, (data) => data.data?.ga, daysBack);
};

export const getComparePublisherStats = async (token, logoutAction) => {
  return await getData(token, 'https://lubimyczytac.pl/publisher_data/api/compare', logoutAction, (data) => data.data);
};

export const getPublisherShelfStats = async (token, logoutAction, daysBack, shelfNumber) => {
  return await getData(token, `https://lubimyczytac.pl/publisher_data/api/shelf-stats/${shelfNumber}`, logoutAction, (data) => data.data, daysBack);
};

export const getPublisherGlobalStats = async (token, logoutAction) => {
  return await getData(token, `https://lubimyczytac.pl/publisher_data/api/global-stats/`, logoutAction, (data) => data.data);
};

export const getPublisherBookViewsStats = async (token, logoutAction, bookId, daysBack) => {
  return await getData(token, `https://lubimyczytac.pl/publisher_data/api/external-stats/book-views/${bookId}`, logoutAction, (data) => data.data, daysBack);
};

const getData = async (token, url, logoutAction, dataGetter, daysBack = null) => {
  let body = `token=${token}`;
  if (daysBack) {
    body = `${body}&days_back=${daysBack}`;
  }
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: body,
    });

    if (response.ok) {
      const data = await response.json();
      if (data && data.code === responseCodes.OK) {
        return dataGetter(data);
      } else if (data && data.code === responseCodes.ERROR && data.data.ERR_CODE === errorCodes.SESSION_EXPIRED) {
        logoutAction();
      }
      return null;
    } else {
      console.error('Błąd pobierania danych!');
    }
  } catch (error) {
    console.error(`Wystąpił błąd podczas pobierania danych z adresu ${url}:`, error);
  }
};
